<template>
  <span>
    <v-icon
      :small="small"
      v-if="value"
      :color="trueValueColor"
    >{{$icon(trueValueIcon)}}</v-icon>
    <v-icon
      :small="small"
      v-if="!value && falseValueIcon"
      :color="falseValueColor"
    >{{$icon(falseValueIcon)}}</v-icon>
  </span>
</template>

<script>
export default {
  props: {
    small: Boolean,
    value: Boolean,
    trueValueIcon: String,
    falseValueIcon: String,
    trueValueColor: String,
    falseValueColor: String
  }
}
</script>
